import PageHeader from "components/PageHeader"
import { TabsNavigation } from "components/Tabs"
import { TABS } from "./constants"

import "./Header.styl"

const Header = ({ children = null }) => (
  <div className="wallet-header">
    <div className="wallet-header__items">
      <PageHeader title="Carteiras" className="wallet-list__header">
        {children}
      </PageHeader>
    </div>
    <nav className="wallet-header__navigation">
      <TabsNavigation tabs={TABS} />
    </nav>
  </div>
)

export default Header
