export const TABS = [
  {
    name: "Contratos ativos",
    path: ROUTES.microloansWalletActives.path,
    permission: null,
  },
  {
    name: "Histórico",
    path: ROUTES.microloansWalletHistory.path,
    permission: null,
  },
  {
    name: "Indicadores",
    path: ROUTES.microloansWalletIndicators.path,
    permission: null,
  },
]
