import Can from "components/Can"
import If from "components/If"
import IndicatorsList from "./components/List"
import Subtitle from "components/Typography/Subtitle"
import Header from "../Header"

import useIndicators from "./hooks"
import "./Indicators.styl"
import Icon from "components/Icon"

const Indicators = ({ pageContext: { business } }) => {
  const {
    walletsTotal,
    walletsRenegotiation,
    walletsMicroloans,
    nonPaymentWallets,
    monthlyProductivity,
    monthlyProductivityMicroloans,
    monthlyProductivityRenegotiation,
    toggleDetails,
    details,
  } = useIndicators()
  return (
    <Can
      business={business.business_permission}
      do="wallet-management"
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <section className="wallet-indicators">
        <Header />
        <main className="wallet-indicators__content">
          <section className="indicators">
            <If condition={monthlyProductivityMicroloans.length}>
              <section className="indicators__group indicators__group--microloans">
                <Subtitle className="indicators__title">
                  Produção Microcrédito
                </Subtitle>
                <IndicatorsList
                  list={monthlyProductivityMicroloans}
                  type="PRODUCTIVITY_MICROLOANS"
                  className="indicators__list"
                />
              </section>
            </If>
            <If condition={monthlyProductivityRenegotiation.length}>
              <section className="indicators__group indicators__group--reneg">
                <Subtitle className="indicators__title">
                  Produção Renegociação
                </Subtitle>
                <IndicatorsList
                  list={monthlyProductivityRenegotiation}
                  type="PRODUCTIVITY_RENEGOTIATION"
                  className="indicators__list"
                />
              </section>
            </If>

            <If condition={walletsTotal.length}>
              <section className="indicators__group indicators__group--total-wallet">
                <Subtitle className="indicators__title">
                  Carteira Total
                </Subtitle>
                <IndicatorsList
                  list={walletsTotal}
                  type="WALLET_TOTAL"
                  className="indicators__list indicators__list-total"
                />

                <If
                  condition={
                    walletsMicroloans.length || walletsRenegotiation.length
                  }
                >
                  <details className="indicators__group--accordion">
                    <summary
                      onClick={(e) => toggleDetails(e.target)}
                      className="indicators__group--accordion-summary"
                    >
                      <p className="indicators__group--accordion-title">
                        {details} detalhes
                        <Icon
                          className="indicators__group--accordion-arrow"
                          name="arrow-down"
                        />
                      </p>
                    </summary>

                    <If condition={walletsMicroloans.length}>
                      <section className="indicators__group indicators__group--wallet indicators__group--detail-wallet">
                        <Subtitle className="indicators__title indicators__title--wallet">
                          Carteira Microcrédito
                        </Subtitle>
                        <IndicatorsList
                          list={walletsMicroloans}
                          type="WALLET_MICROLOANS"
                          className="indicators__list indicators__list-microloans"
                        />
                      </section>
                    </If>
                    <If condition={walletsRenegotiation.length}>
                      <section className="indicators__group indicators__group--wallet indicators__group--detail-wallet">
                        <Subtitle className="indicators__title indicators__title--wallet">
                          Carteira Renegociação
                        </Subtitle>
                        <IndicatorsList
                          list={walletsRenegotiation}
                          type="WALLET_RENEGOTIATION"
                          className="indicators__list indicators__list-reneg"
                        />
                      </section>
                    </If>
                  </details>
                </If>
              </section>
            </If>

            {/* <If condition={nonPaymentWallets.length}>
              <section className="indicators__group indicators__group--non-payment">
                <Subtitle className="indicators__title">Inadimplência</Subtitle>
                <IndicatorsList
                  list={nonPaymentWallets}
                  type="NON_PAYMENT_WALLET"
                  className="indicators__list"
                />
              </section>
            </If> */}
          </section>
        </main>
      </section>
    </Can>
  )
}

export default Indicators
