import classnames from "classnames"
import Card from "components/Card"
import Hint from "components/Hint"
import { CARDS } from "./constants"

import "./List.styl"

const IndicatorsList = ({ list, type, className }) => {
  return (
    <ul
      className={classnames("indicators-list", {
        [className]: className,
      })}
    >
      {list?.map(([name, value]) => (
        <li className="indicators-list__item" key={name}>
          <Card className="indicators-list__card">
            <div className="indicators-list__content">
              <span className="indicators-list__label">
                {CARDS[type][name]?.label}
              </span>
              <b className="indicators-list__value">{value}</b>
            </div>
          </Card>
          <Hint className="indicators-list__hint">
            {CARDS[type][name]?.description}
          </Hint>
        </li>
      ))}
    </ul>
  )
}

export default IndicatorsList
