import { useCallback, useEffect, useState } from "react"
import services from "services"

const useIndicators = () => {
  const [walletsTotal, setWalletsTotal] = useState([])
  const [walletsRenegotiation, setWalletsRenegotiation] = useState([])
  const [walletsMicroloans, setWalletsMicroloans] = useState([])
  const [details, setDetails] = useState("Mais")
  const [nonPaymentWallets, setNonPaymentWallets] = useState([])
  const [isDetailsVisible, setIsDetailsVisible] = useState(false)
  const [
    monthlyProductivityRenegotiation,
    setMonthlyProductivityRenegotiation,
  ] = useState([])
  const [monthlyProductivityMicroloans, setMonthlyProductivityMicroloans] =
    useState([])

  const fetchIndicators = useCallback(() => {
    services.microloans.indicators.wallet().then(handleWalletFetch)
    services.microloans.indicators
      .monthlyProductivity()
      .then(handleMonthlyProductivityFetch)
  }, [])

  const handleWalletFetch = ({
    total = [],
    microloans = [],
    renegotiation = [],
  } = {}) => {
    setWalletsMicroloans(microloans)
    setWalletsRenegotiation(renegotiation)
    setWalletsTotal(total)
  }
  const handleMonthlyProductivityFetch = ({
    microloans = [],
    renegotiation = [],
  } = {}) => {
    setMonthlyProductivityMicroloans(microloans)
    setMonthlyProductivityRenegotiation(renegotiation)
  }

  const toggleDetails = (e) => {
    setIsDetailsVisible((prevIsOpen) => !prevIsOpen)
    return isDetailsVisible ? setDetails("Mais") : setDetails("Menos")
  }

  useEffect(() => {
    fetchIndicators()
  }, [fetchIndicators])

  return {
    walletsTotal,
    walletsRenegotiation,
    walletsMicroloans,
    nonPaymentWallets,
    monthlyProductivityRenegotiation,
    monthlyProductivityMicroloans,
    details,
    toggleDetails,
  }
}

export default useIndicators
