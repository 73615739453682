export const CARDS = {
  WALLET_TOTAL: {
    total_active_contracts_value: {
      label: "Saldo total da carteira",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos com menos de 360 dias de atraso",
    },
    total_active_contracts_quantity: {
      label: "Contratos ativos",
      description:
        "Quantidade de contratos ativos da carteira, não considerando os contratos Liquidados, Cancelados ou com atraso acima de 360 dias.",
    },
    total_delayed_contracts_total_value: {
      label: "Saldo total em atraso",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos com situação “Em Atraso”",
    },
    total_delayed_contracts_quantity: {
      label: "Contratos em atraso",
      description: "Quantidade de contratos com situação “Em Atraso”.",
    },
    total_carency_contracts_total_value: {
      label: "Saldo total em carência",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos que estejam em carência.",
    },
    total_carency_contracts_quantity: {
      label: "Contratos em carência",
      description: "Quantidade de contratos em carência.",
    },
  },
  WALLET_MICROLOANS: {
    microloans_active_contracts_total_value: {
      label: "Saldo total da carteira",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos de microcrédito com menos de 360 dias de atraso.",
    },
    microloans_active_contracts_quantity: {
      label: "Contratos ativos",
      description:
        "Quantidade de contratos ativos de microcrédito da carteira, não considerando Liquidados, Cancelados ou com atraso acima de 360 dias.",
    },
    microloans_delayed_contracts_total_value: {
      label: "Saldo total em atraso",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos de microcrédito com situação “Em Atraso”.",
    },
    microloans_delayed_contracts_quantity: {
      label: "Contratos em atraso",
      description:
        "Quantidade de contratos de microcrédito com a situação “Em Atraso”.",
    },
    microloans_carency_contracts_total_value: {
      label: "Saldo total em carência",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos de microcrédito que estejam em carência.",
    },
    microloans_carency_contracts_quantity: {
      label: "Contratos em carência",
      description: "Quantidade de contratos de microcrédito em carência.",
    },
  },
  WALLET_RENEGOTIATION: {
    renegotiation_active_contracts_total_value: {
      label: "Saldo total da carteira",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos de renegociação com menos de 360 dias de atraso.",
    },
    renegotiation_active_contracts_quantity: {
      label: "Contratos ativos",
      description:
        "Quantidade de contratos ativos de renegociação da carteira, não considerando Liquidados, Cancelados ou com atraso acima de 360 dias.",
    },
    renegotiation_delayed_contracts_total_value: {
      label: "Saldo total em atraso",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos de renegociação com situação “Em Atraso”.",
    },
    renegotiation_delayed_contracts_quantity: {
      label: "Contratos em atraso",
      description:
        "Quantidade de contratos de renegociação com a situação “Em Atraso”.",
    },
    renegotiation_carency_contracts_total_value: {
      label: "Saldo total em carência",
      description:
        "Soma do saldo devedor, a valor presente, de todos os contratos de renegociação que estejam em carência.",
    },
    renegotiation_carency_contracts_quantity: {
      label: "Contratos em carência",
      description: "Quantidade de contratos de renegociação em carência.",
    },
  },
  PRODUCTIVITY_RENEGOTIATION: {
    renegotiation_amount_financed_month: {
      label: "Volume financiado",
      description:
        "Valor total financiado em renegociações para clientes no mês atual",
    },
    renegotiation_contracting_month_count: {
      label: "Contratações",
      description:
        "Quantidade de contratações de renegociações realizadas no mês atual",
    },
    renegotiation_amount_repaid_month: {
      label: "Reembolso",
      description: "Reembolso de contratos de renegociação no mês atual",
    },
  },
  PRODUCTIVITY_MICROLOANS: {
    microloans_amount_financed_month: {
      label: "Volume financiado",
      description:
        "Valor total financiado em microcrédito para clientes no mês atual",
    },
    microloans_contracting_month_count: {
      label: "Contratações",
      description:
        "Quantidade de contratações de microcrédito realizadas no mês atual",
    },
    microloans_amount_repaid_month: {
      label: "Reembolso",
      description: "Reembolso de contratos de microcrédito no mês atual",
    },
  },
  NON_PAYMENT_WALLET: {
    total_wallet_balance_on_time: {
      label: "Saldo total da carteira em dia",
      description:
        "A soma do saldo devedor, a valor presente, de todos os contratos em dia.",
    },
    total_wallet_balance_delay_very_short: {
      label: "Saldo total da carteira em atraso curtíssimo",
      description:
        "A soma do saldo devedor, a valor presente, de todos os contratos com atraso menor que 5 dias.",
    },
    total_wallet_balance_short: {
      label: "Saldo total da carteira em atraso curto",
      description:
        "A soma do saldo devedor, a valor presente, de todos os contratos com atraso de 5 a 60 dias.",
    },
    total_wallet_balance_delay_long: {
      label: "Saldo total da carteira em atraso longo",
      description:
        "A soma do saldo devedor, a valor presente, de todos os contratos com atraso de 61 a 360 dias.",
    },
    total_non_payment: {
      label: "Inadimplência total",
      description:
        "Porcentagem de contratos da carteira que estão em atraso curtíssimo, curto e longo.",
    },
    non_payment_delay_very_short: {
      label: "Inadimplência de atraso curtíssimo",
      description:
        "Porcentagem de contratos da carteira que estão em atraso curtíssimo.",
    },
    non_payment_delay_short: {
      label: "Inadimplência de atraso curto",
      description:
        "Porcentagem de contratos da carteira que estão em atraso curto.",
    },
    non_payment_delay_long: {
      label: "Inadimplência de atraso longo",
      description:
        "Porcentagem de contratos da carteira que estão em atraso longo.",
    },
  },
}
